import React, { useState, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { IcoLens, IcoCanc, IcoEdit } from '@icons';
import { /* IcoScenario ,*/ ArrowsMeeting } from '@icons';
import { deleteProject, putChangeStatusOnDraft } from '@lib/api';
import { InfoToast, SuccessToast, DangerToast } from '@components/GlobalToastList';
import LoadingSpinner from '@components/loading-spinner';

const optionsStatus = [
  {
    value: 'DRAFT',
    label: 'Bozza',
  },
  {
    value: 'SUBMITTED',
    label: 'Inviato',
  },
];

const ActionBtnRenderer = ({ data, setRefresh }) => {
  const id = data.id;
  const [project, setProject] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [showModalEditStatus, setShowModalEditStatus] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //console.log('project', project);

  useEffect(() => {
    setProject(data);

    setSelectedStatus(
      optionsStatus
        .filter((optionStatus) => optionStatus.value === data.status)
        .map((filteredStatus) => ({
          value: filteredStatus.value,
          label: filteredStatus.label,
        }))
    );
  }, [data]);

  const handleCloseModal = (setShowModal) => setShowModal(false);
  const handleShowModal = (setShowModal) => {
    console.log('setShowModal');
    setShowModal(true);
  };

  const onChange = (e, fieldName) => {
    setSelectedStatus(e);

    setProject(() => ({
      ...project,
      [fieldName]: e.value,
    }));
  };

  const handleEditStatus = async () => {
    setIsLoading(true);
    InfoToast('Salvataggio...', 'Stiamo apportando le modifiche', false);
    try {
      await putChangeStatusOnDraft(id, 30000);

      SuccessToast('Operazione conclusa', 'Stato modificato correttamente');
      setIsLoading(false);
      setShowModalEditStatus(false);
      setRefresh(true);
    } catch (error) {
      DangerToast('Attenzione', error.message);
      setIsLoading(false);
    }
  };

  const handleDelete = async (itemID) => {
    //console.log('itemID', itemID);
    setIsLoading(true);
    const deleteResult = await deleteProject(itemID);
    //console.log('deleteResult', deleteResult);

    if (deleteResult.message) {
      SuccessToast('Operazione conclusa', 'Progetto eliminato correttamente');
      setIsLoading(false);
      setShowModalDelete(false);
      setRefresh(true);
    } else {
      setIsLoading(false);
      DangerToast('Attenzione', "Errore nell'eliminazione del progetto");
    }
  };

  const detailsTooltip = (props) => <Tooltip {...props}>Dettagli</Tooltip>;
  const editTooltip = (props) => <Tooltip {...props}>Modifica</Tooltip>;
  const statusTooltip = (props) => <Tooltip {...props}>Modifica stato</Tooltip>;
  const deleteTooltip = (props) => <Tooltip {...props}>Elimina</Tooltip>;

  return (
    <>
      <div>
        <OverlayTrigger placement='top' overlay={detailsTooltip}>
          <Button variant='link' href={'progetti/' + id}>
            <IcoLens className='second-color ico-small edit mr-3' />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={editTooltip}>
          <Button variant='link' href={'valutazione/' + id}>
            <IcoEdit className='second-color ico-small edit mr-3' />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={statusTooltip}>
          <Button
            variant='link'
            onClick={() => handleShowModal(setShowModalEditStatus)}
            disabled={project.status === 'DRAFT' ? true : false}>
            <ArrowsMeeting className='second-color ico-small status mr-3' />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger placement='top' overlay={deleteTooltip}>
          <Button
            variant='link'
            onClick={() => handleShowModal(setShowModalDelete)}
            disabled={project.status === 'SUBMITTED' ? true : false}>
            <IcoCanc className='second-color ico-small cancel mr-0' />
          </Button>
        </OverlayTrigger>

        <Modal
          show={showModalEditStatus}
          onHide={() => handleCloseModal(setShowModalEditStatus)}
          centered>
          {isLoading && <LoadingSpinner />}
          <Modal.Header closeButton>
            <Modal.Title>Attenzione</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              name='status'
              value={selectedStatus}
              options={optionsStatus}
              onChange={(e) => onChange(e, 'status')}
            />
            <p className='mt-4'>Sei sicuro di voler modificare lo stato del progetto?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='success' onClick={() => handleCloseModal(setShowModalEditStatus)}>
              Annulla
            </Button>
            <Button variant='primary' onClick={() => handleEditStatus(id)}>
              Conferma
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModalDelete} onHide={() => handleCloseModal(setShowModalDelete)} centered>
          {isLoading && <LoadingSpinner />}
          <Modal.Header closeButton>
            <Modal.Title>Attenzione</Modal.Title>
          </Modal.Header>
          <Modal.Body>Sei sicuro di voler eliminare il progetto?</Modal.Body>
          <Modal.Footer>
            <Button variant='success' onClick={() => handleCloseModal(setShowModalDelete)}>
              Annulla
            </Button>
            <Button variant='danger' onClick={() => handleDelete(id)}>
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ActionBtnRenderer;
