import { useState } from 'react';
import TutorialsButtons from './TutorialsButtons';
import { Close } from '@icons';

const TutorialsButtonWrapper = ({ url, isThanks, videoTitle }) => {
  const [showVideo, setShowVideo] = useState(false);

  const renderTutorialsButtons = (variant) => (
    <TutorialsButtons url={url} variant={variant} setState={setShowVideo} />
  );

  const renderVideoPlayer = () => (
    <div id='video-player' className='d-flex flex-column justify-content-end align-items-center'>
      <div className='w-100 pl-3'>
        <h4>{videoTitle}</h4>
      </div>
      <iframe
        width='450'
        height='253'
        src={url}
        title='YouTube video player'
        style={{ marginBottom: '20px', border: '0px' }}></iframe>
      <button onClick={() => setShowVideo(false)}>
        <Close />
      </button>
    </div>
  );

  return (
    <div style={{ zIndex: showVideo && isThanks ? '9999' : '' }}>
      {isThanks && !showVideo && renderTutorialsButtons('thanks')}
      <div className='tutorial-button-container d-flex flex-column justify-content-end align-items-end'>
        {!showVideo && !isThanks && <div>{renderTutorialsButtons('base')}</div>}
        {showVideo && renderVideoPlayer()}
      </div>
    </div>
  );
};

export default TutorialsButtonWrapper;
