import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  Card,
} from 'react-bootstrap';
import { getTranslations, getRatings, getEsgCategories, getTypologicalAverage } from '@lib/api';
//import { Trans } from '@lib/i18n';
import { Ico94, Ico95, Ico96, Ico98 } from '@icons/new';
import { TooltipInfoIcon } from '@icons';
import RadarChart from '@components/project/radarChart/RadarChart';

const ESGDetails = ({ currentProject }) => {
  const [translations, setTranslations] = useState([]);
  const [project, setProject] = useState({});
  const [ratings, setRatings] = useState([]);
  const [scoreESG, setScoreEsg] = useState('');
  const [femaleIndicator, setFemaleIndicator] = useState(null);
  const [environmentalSubcategories, setEnvironmentalSubcategories] = useState([]);
  const [socialSubcategories, setSocialSubcategories] = useState([]);
  const [governanceSubcategories, setGovernanceSubcategories] = useState([]);
  const [dataChartEnvironment, setDataChartEnvironment] = useState([]);
  const [dataChartSocial, setDataChartSocial] = useState([]);
  const [dataChartGovernance, setDataChartGovernance] = useState([]);
  const [projectRatings, setProjectRatings] = useState({});
  const [typologicalAverageEsg, setTypologicalAverageEsg] = useState('');
  const [typologicalScoringEsg, setTypologicalScoringEsg] = useState('');
  const [radarDivWidth, setRadarDivWidth] = useState(0);
  const [indicators, setIndicators] = useState([]);
  const [numberOfDuplicate, setNumberOfDuplicate] = useState(0);
  //console.log('project.esgratings', project.esgratings);
  //console.log('ratings', ratings);
  //console.log('projectRatings', projectRatings);

  const resizeObserver = new ResizeObserver(entries => {
    entries.forEach(entry => {
      setRadarDivWidth(entry.contentRect.width);
    });
  });

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    getRatings().then((retrievedRatings) => {
      setRatings(retrievedRatings);
    });

    getEsgCategories().then(retrievedCategories => {
      setEnvironmentalSubcategories(retrievedCategories.filter(cat => cat.code === 'E').map(filteredCat => filteredCat.esgsubcategories).flat());
      setSocialSubcategories(retrievedCategories.filter(cat => cat.code === 'S').map(filteredCat => filteredCat.esgsubcategories).flat());
      setGovernanceSubcategories(retrievedCategories.filter(cat => cat.code === 'G').map(filteredCat => filteredCat.esgsubcategories).flat());
    });

    setProject(currentProject);

    if (currentProject.esgratings) {
      const totalScoreEsg = currentProject.esgratings && currentProject.esgratings.filter(el => el.type === 'total').map(el => el.scorePerc);

      setScoreEsg(totalScoreEsg.length > 0 ? totalScoreEsg.join().replace('.', ',') : 'N/A');
    }

    /* if (currentProject.femaleaud) { */
      setFemaleIndicator(currentProject.femaleaud);
    /* } */
  }, [currentProject]);

  useEffect(() => {
    if (Object.keys(project).length > 0) {
      getTypologicalAverage(project.id).then((retrievedTypologicalAverage) => {
        //console.log('retrievedTypologicalAverage', retrievedTypologicalAverage);
        setTypologicalAverageEsg(retrievedTypologicalAverage.response ? retrievedTypologicalAverage.response.avgEsgRating : 'N/A');
        setTypologicalScoringEsg(retrievedTypologicalAverage.response ? retrievedTypologicalAverage.response.avgEsgScore?.toLocaleString('it-IT', {minimumFractionDigits: 0, maximumFractionDigits: 2}) : 'N/A');
      });

      setProjectRatings(project.esgratings.length > 0 ? 
        project.esgratings.filter(rating => rating.type === 'total').map(filteredRating => filteredRating.rating).join() : {}
      );

      if (project.esgratings.length > 0) {
        setProjectRatings(() => ({ 
          esg: project.esgratings.filter(rating => rating.type === 'total').map(filteredRating => filteredRating.rating).join(),
          E: project.esgratings.filter(rating => rating.type === 'category' && rating.category === 'E').map(filteredRating => filteredRating.rating).join(),
          S: project.esgratings.filter(rating => rating.type === 'category' && rating.category === 'S').map(filteredRating => filteredRating.rating).join(),
          G: project.esgratings.filter(rating => rating.type === 'category' && rating.category === 'G').map(filteredRating => filteredRating.rating).join(),
        }));
      }

      setDataChartEnvironment(
        project.esgratings.filter(esgrating => esgrating.category === 'E' && esgrating.type === 'subcategory')
          .sort((ratingA, ratingB) => ratingA.scorePerc - ratingB.scorePerc)
          .map(filteredRating => ({
            axis: environmentalSubcategories.filter(sub => sub.code === filteredRating.subcategory).map(filteredSub => filteredSub.text).join(),
            value: filteredRating.scorePerc,
          }))
      );

      setDataChartSocial(
        project.esgratings.filter(esgrating => esgrating.category === 'S' && esgrating.type === 'subcategory')
          .sort((ratingA, ratingB) => ratingA.scorePerc - ratingB.scorePerc)
          .map(filteredRating => ({
            axis: socialSubcategories.filter(sub => sub.code === filteredRating.subcategory).map(filteredSub => filteredSub.text).join(),
            value: filteredRating.scorePerc,
          }))
      );

      setDataChartGovernance(
        project.esgratings.filter(esgrating => esgrating.category === 'G' && esgrating.type === 'subcategory')
          .sort((ratingA, ratingB) => ratingA.scorePerc - ratingB.scorePerc)
          .map(filteredRating => ({
            axis: governanceSubcategories.filter(sub => sub.code === filteredRating.subcategory).map(filteredSub => filteredSub.text).join(),
            value: filteredRating.scorePerc,
          }))
      );
    }
  }, [project, environmentalSubcategories, governanceSubcategories, socialSubcategories]);

  useEffect(() => {
    if (dataChartEnvironment.length > 0) {
      resizeObserver.observe(document.querySelector('#radar-1'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dataChartEnvironment]);

  useEffect(() => {
    functionDrawRadar();
  }, [dataChartEnvironment, radarDivWidth]);

  useEffect(() => {
    const { esg, ...restProjectRatings } = projectRatings;
    let tempIndicators = [];
    
    const duplicateElements = Object.values(restProjectRatings).filter((value, index) => Object.values(restProjectRatings).indexOf(value) !== index);

    if (duplicateElements.length === 0) {
      setNumberOfDuplicate(0);
    } else {
      setNumberOfDuplicate(Object.values(restProjectRatings).filter(val => val === duplicateElements[0]).length);
    }

    if (Object.keys(projectRatings).length > 0) {
      ratings.sort((ratingA, ratingB) => ratingA.min - ratingB.min).filter(rating => {
        if (Object.values(restProjectRatings).some(code => code === rating.code)) {
          const codes = Object.keys(restProjectRatings).filter(rest => restProjectRatings[rest] === rating.code);

          return tempIndicators.push(
            <Button key={rating.code} className="button-group-rating showRatingESG rounded" size="lg" disabled>
              {codes.map(code => {
                const score = project.esgratings.filter(rating => rating.type === 'category' && rating.category === code)
                .map(filteredRating => filteredRating.scorePerc.toLocaleString('it-IT'));

                return <OverlayTrigger key={code} placement="top" delay={{ show: 200, hide: 400 }} overlay={renderTooltip} popperConfig={{testObj: score}}>
                  <span id={rating.code}>{code}<div className="arrow-down"></div></span>
                </OverlayTrigger>
              })}
            </Button>
          )
        } else {
          return tempIndicators.push(<Button key={rating.code} id={rating.code} variant="outline-dark" className="button-group-rating" size="lg" disabled>
            {/* invisible */}
          </Button>)
        };
      });

      setIndicators(tempIndicators);
    }
  }, [projectRatings, ratings, project.esgratings]);

  const renderTooltip = (props) => {
    let message = "";

    if (props.popper.state) {
      message = props.popper.state.options.testObj
    };

    return (
      <Tooltip id="tooltip-disabled" {...props}>
        {message}
      </Tooltip>
    )
  };

  const indicatorESG = () => {
    let indicator = [];

    const score = project.esgratings.filter(rating => rating.type === 'total').map(filteredRating => filteredRating.scorePerc.toLocaleString('it-IT'));

    ratings.sort((ratingA, ratingB) => ratingA.min - ratingB.min)
    .forEach(rating => { 
      if (rating.code === projectRatings.esg) {
        indicator.push(<OverlayTrigger key={rating.code} placement="top" delay={{ show: 200, hide: 400 }} overlay={renderTooltip} popperConfig={{testObj: score}}>
          <span className="d-inline-block no-to-head">
            <Button className="button-group-rating showRatingESG rounded" size="lg" disabled style={{pointerEvents: 'none'}}>
              <div className="arrow-up"></div>ESG
            </Button>
          </span>
        </OverlayTrigger>)
      } else {
        indicator.push(<Button key={rating.code} variant="outline-dark" className="button-group-rating" size="lg" disabled>{/* invisible */}</Button>)
      }  
  });

    return indicator;
  };

  const RatingComponent = ({rating}) => {
      let customBackgroundColor;

      switch (rating.outlook) {
        case 'red':
          customBackgroundColor = 'red';
          break;
        case 'yellow':
          customBackgroundColor = 'yellow';
          break;
        case 'green':
          customBackgroundColor = 'green';
          break;
        default: 
        customBackgroundColor = 'grey';
      };

      return <Button className={"button-group-base " + customBackgroundColor} size="lg" disabled>{rating.code}</Button>
  };

  const functionDrawRadar = (data, color, divWidth, divId, maxValue) => {
    return <RadarChart data={[data]} radarColor={color} divWidth={divWidth} maxValue={maxValue} />
  };
  
  return (
    <>
      <Card className="cards-utente d-none d-lg-block mb-5" style={{ marginTop: '4rem'}}>
        <Card.Body>
          <Ico98 className="logo-card ico-box-blue" />
          <Card.Title className="margin-title-box text-uppercase pt-3">
            {translations.filter(trans => trans.code === 'ICS038').map(trans => trans.text)}
            <OverlayTrigger
              placement="right"
              delay={{ show: 200, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS038').map(trans => trans.description)}</Tooltip>}
            >
              <TooltipInfoIcon className="tooltip-ico mb-1" />
            </OverlayTrigger>
          </Card.Title>

          <Row className="px-2 align-items-center justify-content-between mb-4 flex-nowrap">
            {/* <Col className="esg-infobox pt-2 flex-nowrap">
              <Row>
                <Col className="esg-infobox pt-2 text-center col-4">
                  <span className="first-column">Rating</span>
                </Col>

                <Col className="esg-infobox pt-2 col-3">
                  <span className="second-column">{projectRatings.esg ?? 'N/A'}</span>
                </Col>

                <Col className="esg-infobox pt-2 col-auto">
                  <span className="second-column">{scoreESG}</span>
                </Col>
              </Row>
            </Col> */}

            <Col className="pr-0">
               <table className='mt-2 ml-auto' style={{fontSize: '1.5rem'}}>
                  <thead>
                     <tr className='py-2'>
                        <td className='pr-4 pb-4'></td>
                        <td className='pr-4 pb-4 esg-header columns'>Puntuale</td>
                        <td className='pr-4 pb-4 esg-header columns'>Tipologico</td>
                     </tr>
                  </thead>
                  <tbody>
                     <tr>
                        <td className='pr-4 pb-4 esg-header rows text-uppercase'>SCORING</td>
                        <td className='pr-4 pb-4 f-600'>{scoreESG}</td>
                        <td className='pr-4 pb-4'>{typologicalScoringEsg}</td>
                     </tr>
                     <tr>
                        <td className='pr-4 pb-4 esg-header rows text-uppercase'>RATING</td>
                        <td className='pr-4 pb-4 f-600'>{projectRatings.esg ?? 'N/A'}</td>
                        <td className='pr-4 pb-4'>{typologicalAverageEsg}</td>
                     </tr>
                  </tbody>
               </table>
            </Col>

            <Col className="esg-infobox text-center pl-0" style={{flexGrow: 2, marginTop: numberOfDuplicate === 0 ? '0.5rem' : numberOfDuplicate === 2 ? '-1.5rem' : '-3.5rem'}}>
              <Row style={{flexDirection: 'column'}}>
                <Col>
                  <ButtonGroup className="buttongroup-esg indicator align-items-end" id="buttongroup-indicators">
                    {Object.keys(projectRatings).length > 0 ? indicators : null}
                  </ButtonGroup>
                </Col>

                <Col className="mt-2 mb-2">
                  <ButtonGroup className="buttongroup-esg">
                  {ratings.sort((ratingA, ratingB) => ratingA.min - ratingB.min )
                    .map(rating => { return <RatingComponent rating={rating} key={rating.code} />})}
                  </ButtonGroup>
                </Col>
                <Col>
                  <ButtonGroup className="buttongroup-esg indicator">
                    {Object.keys(projectRatings).length > 0 ? indicatorESG() : null}
                  </ButtonGroup>
                </Col>
              </Row>
            </Col>

            {project.proponentCode === "AUD" && femaleIndicator ?
               <>
                  <Col className="pr-0">
                     <table className='mt-2 mr-auto' style={{fontSize: '1.5rem'}}>
                        <thead>
                           <tr className='py-2'>
                              <td className='pr-0 pb-4 esg-header columns'></td>
                              <td className='pb-4 esg-header columns'>{translations.filter(trans => trans.code === 'ICS114').map(trans => trans.text)}</td>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td className='pr-4 pb-4 esg-header rows text-uppercase'>SCORING</td>
                              <td className='pr-4 pb-4 f-600'>{femaleIndicator.scorePerc}%</td>
                              
                           </tr>
                           <tr>
                              <td className='pr-4 pb-4 esg-header rows text-uppercase'>RATING</td>
                              <td className='pr-4 pb-4 f-600'>{femaleIndicator.rating}</td>
                              
                           </tr>
                        </tbody>
                     </table>
                  </Col>
               </> :
               null
            }
          </Row>
        </Card.Body>
      </Card>

      <Row className="gap-1 mb-5" style={{ marginTop: '4rem'}}>
        <Col>
          <Card className="cards-utente d-none d-lg-block pb-0 mt-0" id="div-radar-1">
            <Card.Body>
              <Ico94 className="logo-card ico-box-green" />
              <Card.Title className="text-uppercase pt-3">
                {translations.filter(trans => trans.code === 'ICS039').map(trans => trans.text)}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 200, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS039').map(trans => trans.description)}</Tooltip>}
                >
                  <TooltipInfoIcon className="tooltip-ico mb-1" />
                </OverlayTrigger>
              </Card.Title>

              <Row className="align-items-center justify-content-center" id="radar-1">
                {/* {dataChartEnvironment.length > 0 && radarDivWidth > 0 ? 
                  <RadarChart data={[dataChartEnvironment]} radarColor={'#c2c923'} divWidth={radarDivWidth} /> : 
                  <span className="not-applicable">N/A</span>
                } */}
                {dataChartEnvironment.length > 0 && radarDivWidth ? 
                  functionDrawRadar(dataChartEnvironment, '#c2c923', radarDivWidth, 'radar-1', 100) : 
                  <span className="not-applicable">N/A</span>
                }
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="cards-utente d-none d-lg-block pb-0 mt-0" id="div-radar-2">
            <Card.Body>
              <Ico95 className="logo-card ico-box-light-blue" />
              <Card.Title className="text-uppercase pt-3">
                {translations.filter(trans => trans.code === 'ICS040').map(trans => trans.text)}
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 200, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS040').map(trans => trans.description)}</Tooltip>
                  }
                >
                  <TooltipInfoIcon className="tooltip-ico mb-1" />
                </OverlayTrigger>
              </Card.Title>

              <Row className="align-items-center justify-content-center" id="radar-2">
                {/* {dataChartSocial.length > 0 ? 
                  <RadarChart data={[dataChartSocial]} radarColor={'#08a2aa'} divWidth={getWidth('radar-2')} /> : 
                  <span className="not-applicable">N/A</span>
                } */}
                {dataChartSocial.length > 0 && radarDivWidth ? 
                  functionDrawRadar(dataChartSocial, '#08a2aa', radarDivWidth, 'radar-2', 100) : 
                  <span className="not-applicable">N/A</span>
                }
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card className="cards-utente d-none d-lg-block pb-0 mt-0" id="div-radar-3">
            <Card.Body>
              <Ico96 className="logo-card ico-box-blue" />
              <Card.Title className="text-uppercase pt-3">
                {translations.filter(trans => trans.code === 'ICS048').map(trans => trans.text)}
                <OverlayTrigger
                  placement="left"
                  delay={{ show: 200, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">{translations.filter(trans => trans.code === 'ICS048').map(trans => trans.description)}</Tooltip>
                  }
                >
                  <TooltipInfoIcon className="tooltip-ico mb-1" />
                </OverlayTrigger>
              </Card.Title>

              <Row className="align-items-center justify-content-center" id="radar-3">
                {/* {dataChartGovernance.length > 0 ? 
                  <RadarChart data={[dataChartGovernance]} radarColor={'#1c2743'} divWidth={getWidth('radar-3')} /> : 
                  <span className="not-applicable">N/A</span>
                } */}
                {dataChartGovernance.length > 0 && radarDivWidth ? 
                  functionDrawRadar(dataChartGovernance, '#1c2743', radarDivWidth, 'radar-3') : 
                  <span className="not-applicable">N/A</span>
                }
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ESGDetails;
