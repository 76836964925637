import React, { useState, useEffect, Fragment } from 'react';
import { Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
//import { rounder } from '@lib/helpers';
//import { Trans } from '@lib/i18n';
import { Ico44 } from '@icons/new';
import { TooltipInfoIcon } from '@icons';
import {
  getInterventions,
  getStructureClusters,
  getFinancings,
  getSroiRatingsParams,
} from '@lib/api';

const GeneralDataDetails = ({ currentProject, sectorCode, proponentCode, translations }) => {
  const [project, setProject] = useState([]);
  const [proponent, setProponent] = useState([]);
  const [intervention, setIntervention] = useState([]);
  const [financing, setFinancing] = useState([]);
  //const [size, setSize] = useState([]);
  const [sector, setSector] = useState([]);
  const [subsector, setSubsector] = useState([]);

  const [region, setRegion] = useState([]);
  const [uts, setUts] = useState([]);
  const [town, setTown] = useState([]);
  const [address, setAddress] = useState([]);
  const [cap, setCap] = useState([]);
  const [residentPopulation, setResidentPopulation] = useState([]);

  const [investmentStartDate, setInvestmentStartDate] = useState(null);
  const [operationStartDate, setOperationStartDate] = useState(null);
  const [expectedLifeSpan, setExpectedLifeSpan] = useState(null);

  const [sroiParams, setSroiParams] = useState([]);
  const [sroiRatings, setSroiRatings] = useState({});
  console.log('project', project);
  //console.log('financing', financing);

  useEffect(() => {
    getSroiRatingsParams().then((retrievedSroiParams) => {
      setSroiParams(retrievedSroiParams);
    });

    setProject(currentProject);

    if (currentProject.sroiratings) {
      setSroiRatings({
        totalCapex: currentProject.sroiratings
          .filter((rating) => rating.code === 'TOTALCAPEX')
          .map((filtredRating) =>
            filtredRating.value.toLocaleString('it-IT', { maximumFractionDigits: 0 })
          ),
      });
    }
  }, [currentProject]);

  useEffect(() => {
    getInterventions().then((retrievedInterventions) => {
      setIntervention(
        retrievedInterventions
          .filter((inter) => inter.code === project.interventionClassCode)
          .map((filteredInter) => filteredInter.text)
          .toString()
      );
    });

    getFinancings().then((retrievedFin) => {
      setFinancing(
        retrievedFin
          .filter((fin) => fin.code === project.financingTypeCode)
          .map((filteredFin) => filteredFin.text)
          .toString()
      );
    });

    setProponent(project.proponents?.name);
    //setSize(project.sizes?.text);
    setSector(project.sectors?.text);

    getStructureClusters().then((retrievedClusters) => {
      //console.log('retrievedClusters', retrievedClusters);
      setSubsector(
        retrievedClusters
          .filter((el) => el.code === project.structureClusterCode)
          .map((retrievedCluster) => retrievedCluster.cluster)
      );
    });

    setRegion(project.locations?.nomeRegione);
    setUts(project.locations?.nomeUnitaTerritoriale);
    setTown(project.locations?.nomeComune);
    setAddress(project.address?.address);
    setCap(project.address?.cap);
    setResidentPopulation(project.ispra?.popRes011);

    setInvestmentStartDate(project.investmentStartsAt);
    setOperationStartDate(project.operationStartsAt);
    setExpectedLifeSpan(project.expectedLifeSpan);
  }, [
    project.proponents,
    project.interventionClassCode,
    project.structureClusterCode,
    project.financingTypeCode,
    project.sizes,
    project.sectors,
    project.subsectors,
    project.locations,
    project.address,
    project.ispra,
    project.description,
    project.investmentStartsAt,
    project.operationStartsAt,
    project.expectedLifeSpan,
  ]);

  const renderTooltip = (props) => {
    let message = '';

    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };
  console.log('subsec', subsector);
  return (
    <>
      <Card className='cards-utente h-95 d-none d-lg-block'>
        <Card.Body>
          <Ico44 className='logo-card ico-box-blue' />
          <Card.Title className='margin-title-box text-uppercase pt-3'>dati generali</Card.Title>

          <Row className='mb-0' id='dati-generali-details'>
            <Col>
              {/* Ente proponente */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>Ente proponente</Col>
                <Col className='text-right basis-fit-content pl-0'>{proponent}</Col>
              </Row>

              {/* tipo di investimento */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>
                  {proponentCode === 'ET' ||
                  proponentCode === 'LIGHTPRO' ||
                  proponentCode === 'LIGHTNOPRO'
                    ? 'Tipo di finanziamento'
                    : 'Tipo di intervento'}
                </Col>
                <Col className='text-right basis-fit-content pl-0'>
                  {proponentCode === 'ET' ||
                  proponentCode === 'LIGHTPRO' ||
                  proponentCode === 'LIGHTNOPRO'
                    ? financing
                    : intervention}
                </Col>
              </Row>

              {/* settore */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>Settore</Col>
                <Col className='text-right basis-fit-content pl-0'>{sector}</Col>
              </Row>

              {/* Sottosettore */}
              {/* {proponentCode === 'ET' ||
              proponentCode === 'LIGHTPRO' ||
              proponentCode === 'LIGHTNOPRO' ? ( */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>Sottosettore</Col>
                <Col className='text-right basis-fit-content pl-0'>
                  {subsector.length ? subsector : '-'}
                </Col>
              </Row>
              {/* ) : null} */}

              {/* durata investimento */}
              {proponentCode !== 'ET' &&
              proponentCode !== 'LIGHTPRO' &&
              proponentCode !== 'LIGHTNOPRO' ? (
                <Row className='border-bottom'>
                  <Col className='f-600 basis-fit-content pr-0'>
                    {translations
                      .filter((trans) => trans.code === 'ICS059')
                      .map((trans) => trans.text)}
                  </Col>
                  <Col className='text-right basis-fit-content pl-0'>
                    {expectedLifeSpan}&nbsp;anni
                  </Col>
                </Row>
              ) : null}

              {/* Anno inizio investimento */}
              <Row
                className={
                  proponentCode === 'ET' ||
                  proponentCode === 'LIGHTPRO' ||
                  proponentCode === 'LIGHTNOPRO'
                    ? 'border-bottom'
                    : 'border-bottom'
                }>
                <Col className='f-600 basis-fit-content pr-0'>Anno inizio investimento</Col>
                <Col className='text-right basis-fit-content pl-0'>{investmentStartDate}</Col>
              </Row>

              {/* Anno inizio gestione operativa */}
              {proponentCode === 'ET' ||
              proponentCode === 'LIGHTPRO' ||
              proponentCode === 'LIGHTNOPRO' ? (
                <Row className='border-bottom'>
                  <Col className='f-600 basis-fit-content pr-0'>
                    {translations
                      .filter((trans) => trans.code === 'ICS060')
                      .map((trans) => trans.text)}
                  </Col>
                  <Col className='text-right basis-fit-content pl-0'>{operationStartDate}</Col>
                </Row>
              ) : null}

              {/* ammontare dell'investimento */}
              <Row className='justify-content-between'>
                <Col className='f-600 pr-0' id='totalcapex-text'>
                  {sroiParams
                    .filter((sroiParam) => sroiParam.code === 'TOTALCAPEX')
                    .map((filteredSroiParam) => {
                      return (
                        <Fragment key={filteredSroiParam.code}>
                          {filteredSroiParam.text}
                          {filteredSroiParam.description ? (
                            <OverlayTrigger
                              placement='right'
                              delay={{ show: 200, hide: 400 }}
                              overlay={renderTooltip}
                              popperConfig={{ testObj: filteredSroiParam.description }}>
                              <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                            </OverlayTrigger>
                          ) : null}
                        </Fragment>
                      );
                    })}
                </Col>
                <Col className='text-right basis-fit-content pl-0 col-auto'>
                  {project.interventionClassCode === 'NOINTPURCH'
                    ? `€ ${project?.totalInvestment?.toLocaleString('it-IT', {
                        maximumFractionDigits: 0,
                      })}`
                    : sroiRatings.totalCapex?.length > 0
                    ? `€ ${sroiRatings.totalCapex}`
                    : 'N/A'}
                </Col>
              </Row>
            </Col>

            <Col>
              {/* regione */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>
                  {translations
                    .filter((trans) => trans.code === 'ICS009')
                    .map((trans) => trans.text)}
                </Col>
                <Col className='text-right basis-fit-content pl-0'>{region}</Col>
              </Row>

              {/* provincia */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>
                  {translations
                    .filter((trans) => trans.code === 'ICS010')
                    .map((trans) => trans.text)}
                </Col>
                <Col className='text-right basis-fit-content pl-0'>{uts}</Col>
              </Row>

              {/* città */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>
                  {translations
                    .filter((trans) => trans.code === 'ICS011')
                    .map((trans) => trans.text)}
                </Col>
                <Col className='text-right basis-fit-content pl-0'>
                  {town}
                  {sectorCode === 'EVENTI' ||
                  proponentCode === 'ET' ||
                  proponentCode === 'LIGHTPRO' ||
                  proponentCode === 'LIGHTNOPRO'
                    ? null
                    : ` - ${cap}`}
                </Col>
              </Row>

              {/* indirizzo */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>
                  {translations
                    .filter((trans) => trans.code === 'ICS058')
                    .map((trans) => trans.text)}
                </Col>
                <Col className='text-right basis-fit-content pl-0'>{address}</Col>
              </Row>

              {/* cap */}
              {proponentCode === 'ET' ||
              proponentCode === 'LIGHTPRO' ||
              proponentCode === 'LIGHTNOPRO' ? (
                <Row className='border-bottom'>
                  <Col className='f-600 basis-fit-content pr-0'>
                    {translations
                      .filter((trans) => trans.code === 'ICS013')
                      .map((trans) => trans.text)}
                  </Col>
                  <Col className='text-right basis-fit-content pl-0'>{cap}</Col>
                </Row>
              ) : null}

              {/* popolazione residente */}
              <Row className='border-bottom'>
                <Col className='f-600 basis-fit-content pr-0'>
                  {translations
                    .filter((trans) => trans.code === 'ICS057')
                    .map((trans) => trans.text)}
                </Col>
                <Col className='text-right basis-fit-content pl-0'>
                  {residentPopulation?.toLocaleString('it-IT', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>

              {/* durata investimento */}
              {proponentCode === 'ET' ||
              proponentCode === 'LIGHTPRO' ||
              proponentCode === 'LIGHTNOPRO' ? (
                <Row>
                  <Col className='f-600 basis-fit-content pr-0'>
                    {translations
                      .filter((trans) => trans.code === 'ICS059')
                      .map((trans) => trans.text)}
                  </Col>
                  <Col className='text-right basis-fit-content pl-0'>
                    {expectedLifeSpan}&nbsp;anni
                  </Col>
                </Row>
              ) : null}

              {/* anno inizio gestione operativa */}
              {proponentCode !== 'ET' &&
              proponentCode !== 'LIGHTPRO' &&
              proponentCode !== 'LIGHTNOPRO' ? (
                <Row>
                  <Col className='f-600 basis-fit-content pr-0'>
                    {translations
                      .filter((trans) => trans.code === 'ICS060')
                      .map((trans) => trans.text)}
                  </Col>
                  <Col className='text-right basis-fit-content pl-0'>{operationStartDate}</Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default GeneralDataDetails;
