import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { getTranslations, getProject, getProjectBase64 } from '@lib/api';
import { Redirect } from 'react-router-dom';
import GeneralDataDetails from './components/generalDataDetails';
import ESGDetails from './components/esgDetails';
import SroiDetails from './components/sroiDetails';
import MaterialityDetails from './components/materialityDetails';
import ProjectHeader from '@components/project/ProjectHeader';
import RiskDetails from './components/riskDetails';

function isNumber(str) {
  return !isNaN(str) && !isNaN(parseFloat(str));
}

function isBase64Encoded(str) {
  if (isNumber(str)) return;
  if (typeof str !== 'string' || str.length % 4 !== 0 || /[^A-Za-z0-9+/=]/.test(str)) {
    return false;
  }

  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

const Progetto = (props) => {
  const { projectId, reportNumber } = props.match.params;
  const [isMounted, setIsMounted] = useState(true);
  const history = useHistory();
  const [translations, setTranslations] = useState([]);
  const [project, setProject] = useState({});
  const [isInDetails, setIsInDetails] = useState('general');
  const printRef = React.useRef();
  const [showCells, setShowCells] = useState(false);

  useEffect(() => {
    if (!isBase64Encoded(projectId) && !reportNumber) {
      if (isMounted) {
        getTranslations().then((retrievedTranslation) => {
          setTranslations(retrievedTranslation);
        });

        getProject(projectId).then((retrievedProject) => {
          setProject(retrievedProject);
        });

        setIsInDetails(props.location.pathname === `/progetti/${projectId}` ? true : false);
      }
    }

    if (props.match.path.includes('projectresults')) {
      if (!isBase64Encoded(projectId) || !isBase64Encoded(reportNumber)) {
        history.push('/progetti');
      } else if (isBase64Encoded(projectId) && isBase64Encoded(reportNumber)) {
        if (isMounted) {
          getTranslations().then((retrievedTranslation) => {
            setTranslations(retrievedTranslation);
          });
          getProjectBase64(reportNumber, projectId).then((retrievedProject) => {
            if (!retrievedProject?.code) {
              setProject(retrievedProject);
            } else if (retrievedProject?.code === 418) {
              history.push('/progetti');
            }
          });

          setIsInDetails(
            props.location.pathname === `/projectresults/${projectId}/${reportNumber}`
              ? true
              : false
          );
        }
      }
    }

    // if (isBase64Encoded(projectId)) {
    //   getTranslations().then((retrievedTranslation) => {
    //     setTranslations(retrievedTranslation);
    //   });

    //   getProjectBase64(reportNumber, projectId).then((retrievedProject) => {
    //     setProject(retrievedProject);
    //   });

    //   setIsInDetails(
    //     props.location.pathname === `/projectresults/${projectId}/${reportNumber}` ? true : false
    //   );
    // }
    return () => {
      setIsMounted(false);
    };
  }, [projectId, reportNumber, props.location.pathname, props.match.path, history, isMounted]);

  if (!projectId || !project) return <Redirect to='/progetti' />;

  return (
    <div id='dashboard' ref={printRef} className='megaContent'>
      {project?.id && (
        <div className='container-fluid'>
          <ProjectHeader
            project={project}
            isInDetails={isInDetails}
            translations={translations}
            printRef={printRef}
            setShowCells={setShowCells}
          />

          {(project.sectorCode || project.interventionClassCode) &&
          (project.sectorCode === 'EVENTI' || project.interventionClassCode === 'NOINTPURCH') ? (
            <GeneralDataDetails
              className='mb-5'
              currentProject={project}
              sectorCode={project.sectorCode}
              proponentCode={project.proponentCode}
              translations={translations}
            />
          ) : (
            <>
              <Row className='gap-1'>
                <Col>
                  <GeneralDataDetails
                    className='mb-5'
                    currentProject={project}
                    sectorCode={project.sectorCode}
                    proponentCode={project.proponentCode}
                    translations={translations}
                  />
                </Col>

                <Col xs={4}>
                  <SroiDetails
                    className='mb-5'
                    currentProject={project}
                    sectorCode={project.sectorCode}
                    proponentCode={project.proponentCode}
                  />
                </Col>
              </Row>
            </>
          )}

          <RiskDetails currentProject={project} />
          {/* {process.env.REACT_APP_DEMO_USER && process.env.REACT_APP_DEMO_PASSWORD ? 
            null : 
            <RiskDetails currentProject={project} />
         } */}

          <ESGDetails currentProject={project} />

          <MaterialityDetails
            currentProject={project}
            showCells={showCells}
            setShowCells={setShowCells}
          />
          {/* {process.env.REACT_APP_DEMO_USER && process.env.REACT_APP_DEMO_PASSWORD ? 
            null : 
            <MaterialityDetails currentProject={project} showCells={showCells} setShowCells={setShowCells} />
         } */}
        </div>
      )}
    </div>
  );
};

export default Progetto;
