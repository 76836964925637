import React, { useState, useEffect /* isValidElement */ } from 'react';
import {
  useParams,
  /* BrowserRouter as Router, */
  // Link,
  // useLocation,
} from 'react-router-dom';
//import { Trans } from '@lib/i18n';

import { getTranslations, getProject } from '@lib/api';
import ValutazioneHeader from 'components/valutazione/ValutazioneHeader';
import ProjectHeader from '@components/project/ProjectHeader';
import {
  ValutazioneGeneral,
  ValutazioneESG,
  ValutazioneSROI,
  ValutazioneSROIAUD,
} from '@components/valutazione';
import { getPercentageSROI, getPercentageESG } from '@lib/api/index';

const Valutazione = (props) => {
  const [project, setProject] = useState(null);
  const { projectId = null } = useParams();
  const [translations, setTranslations] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [vanBenefits, setVanBenefits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tutorialFinalButtonVisible, setTutorialFinalButtonVisible] = useState(null);
  console.log('vanBenefits', vanBenefits);

  const activeStep =
    props.location.pathname.indexOf('esg') > 1
      ? 'esg'
      : props.location.pathname.indexOf('sroi') > 1
      ? 'sroi'
      : 'general';

  useEffect(() => {
    getTranslations().then((retrievedTranslation) => {
      setTranslations(retrievedTranslation);
    });

    if (projectId) {
      getProject(projectId).then((retrievedProject) => {
        let tempQuestions = [...retrievedProject.questions];
        tempQuestions = tempQuestions.map((el) => ({
          ...el,
          dependsOnAnswers: el.dependsOnAnswers
            ? el.dependsOnAnswers.split(',').map((el) => Number(el))
            : [],
        }));
        retrievedProject.questions = tempQuestions;

        setProject(retrievedProject);
        setVanBenefits(
          retrievedProject.sroiratings
            .filter((el) => el.code === 'VANBENEFITS')
            .map((el) => Number(el.value))
            .pop()
        );
      });
    } else {
      setProject({});
    }
  }, [projectId]);

  useEffect(() => {
    const fetchData = async () => {
      const obj = { esg: 0, sroi: 0 };
      if (project?.proponentCode === 'ET' || project?.interventionClassCode === 'NOINTPURCH') {
        obj.sroi = 100;
      } else {
        const percentageSROI = await getPercentageSROI(projectId);
        obj.sroi = percentageSROI?.percentage;
      }
      const percentageESG = await getPercentageESG(projectId);
      obj.esg = percentageESG;
      setTutorialFinalButtonVisible(Object.values(obj).every((value) => value === 100));
    };
    if ((projectId || isUpdated) && project) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, isUpdated, project]);

  if (!project) return null;

  return (
    <>
      <div id='valutazione' className='scenari megaContent'>
        <div className='container-fluid'>
          <ProjectHeader project={project} translations={translations} />

          <ValutazioneHeader
            activeStep={activeStep}
            projectId={parseInt(projectId)}
            projectStatus={project.status}
            sectorCode={project.sectorCode}
            interventionClassCode={project.interventionClassCode}
            financingTypeCode={project.financingTypeCode}
            proponentCode={project.proponentCode}
            vanBenefits={vanBenefits}
            translations={translations}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
            clientId={project.clientId}
            requestId={project.requestId}
            updatedAt={project.updatedAt}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />

          {activeStep === 'general' && (
            <ValutazioneGeneral currentProject={project} translations={translations} />
          )}
          {activeStep === 'esg' && (
            <ValutazioneESG currentProject={project} translations={translations} />
          )}
          {activeStep === 'sroi' && project.proponentCode === 'AUD' && (
            <ValutazioneSROIAUD
              currentProject={project}
              translations={translations}
              isUpdated={isUpdated}
              setIsUpdated={setIsUpdated}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {activeStep === 'sroi' && project.proponentCode !== 'AUD' && (
            <ValutazioneSROI currentProject={project} translations={translations} />
          )}
        </div>
      </div>
    </>
  );
};
export default Valutazione;
