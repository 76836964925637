import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Modal,
} from 'react-bootstrap';
import Select from 'react-select';
//import { Trans } from '@lib/i18n';
import {
  getSectors,
  getStructureClusters,
  getProvinceByRegion,
  getRegions,
  getTownByProvince,
  getProponents,
  getInterventions,
  getFinancings,
  getSizes,
  getRequestIdCheck,
  createProject,
  editProject,
} from '@lib/api';
import { TooltipInfoIcon } from '@icons';
import { SuccessToast, DangerToast, InfoToast } from '@components/GlobalToastList';
import OETrans from 'components/translation/OETrans';
import { InputNumber } from 'primereact';
import TutorialsButtonWrapper from 'components/TutorialsButtonWrapper';

const initialState = {
  clientId: '',
  requestId: '',
  description: '',

  name: 'nome placeholder', //unused
  proponentCode: '',
  interventionClassCode: null, //''
  financingTypeCode: null, //''
  sroiEvaluationType: '',
  sizeCode: '',
  totalInvestment: null,
  sectorCode: '',
  structureClusterCode: null, //''
  revenueGenerating: null, //unused

  locationCode: null,
  locations: {
    capoluogo: null,
    codComune: '',
    codComuneNumerico: null,
    codProvincia: '',
    codRegione: '',
    codRipartizione: null,
    codUnitaTerritoriale: '',
    nomeComune: '',
    nomeRegione: '',
    nomeUnitaTerritoriale: '',
    progrComune: '',
    ripartizioneGeografica: '',
    sigla: '',
    tipoUnitaTerritoriale: null,
  },

  address: {
    locationCode: null,
    address: '',
    cap: '',
  },

  investmentStartsAt: new Date().getFullYear(),
  operationStartsAt: new Date().getFullYear(),
  residualLife: 0,
  expectedLifeSpan: null,
};

const ValutazioneGeneral = ({ currentProject, translations }) => {
  const [project, setProject] = useState(
    Object.keys(currentProject).length > 0 ? currentProject : initialState
  );
  const [optionsProponents, setOptionsProponents] = useState([]);
  const [optionsInterventions, setOptionsInterventions] = useState([]);
  const [optionsFinancings, setOptionsFinancings] = useState([]);
  const [allFinancings, setAllFinancings] = useState([]);
  const [optionsSizes, setOptionsSizes] = useState([]);
  const [optionsSectors, setOptionsSectors] = useState([]);
  const [optionsClusters, setOptionsClusters] = useState([]);
  const [optionsRegions, setOptionsRegions] = useState([]);
  const [optionsUtss, setOptionsUtss] = useState([]);
  const [optionsTowns, setOptionsTowns] = useState([]);

  const [selectedProponent, setSelectedProponent] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [selectedIntervention, setSelectedIntervention] = useState([]);
  const [selectedFinancing, setSelectedFinancing] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedUts, setSelectedUts] = useState([]);
  const [selectedTown, setSelectedTown] = useState([]);

  const [requestIDInvalid, setRequestIDInvalid] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalEditInvestment, setShowModalEditInvestment] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isConfirmedInvestment, setIsConfirmedInvestment] = useState(false);

  console.log('project', project);

  useEffect(() => {
    if (localStorage.getItem('ics_role') === '1') {
      project.clientId = localStorage.getItem('ics_client_id');
      project.requestId = localStorage.getItem('ics_request_id');
    }

    getProponents().then((retrievedProponents) => {
      //retrievedProponents.push({code: "LIGHTPRO", name: "Profit Light"});
      setOptionsProponents(
        retrievedProponents.map((proponent) => ({
          value: proponent.code,
          label: proponent.name,
        }))
      );
    });

    getInterventions().then((retrievedInterventions) => {
      setOptionsInterventions(
        retrievedInterventions.map((intervention) => ({
          value: intervention.code,
          type: intervention.sroiEvaluationType,
          label: intervention.text,
        }))
      );
    });

    //setOptionsFinancings(testOptionsFinancings);

    getFinancings().then((retrievedFinancings) => {
      //console.log('retrievedFinancings', retrievedFinancings);
      setOptionsFinancings(
        retrievedFinancings.map((financing) => ({
          value: financing.code,
          label: financing.text,
          active: financing.active,
        }))
      );
    });

    getSizes().then((retrievedSizes) => {
      const orderedSizes = retrievedSizes.sort((sizeA, sizeB) => sizeA.min - sizeB.min);

      setOptionsSizes(
        orderedSizes.map((size) => ({
          value: size.code,
          label: size.text,
          min: size.min,
          max: size.max,
        }))
      );
    });

    getSectors().then((retrievedSectors) => {
      setOptionsSectors(
        retrievedSectors.map((sector) => ({
          value: sector.code,
          label: sector.text,
        }))
      );
    });

    getStructureClusters().then((retrievedClusters) => {
      //console.log('retrievedClusters', retrievedClusters);
      setOptionsClusters(
        retrievedClusters.map((retrievedCluster) => ({
          value: retrievedCluster.code,
          label: retrievedCluster.cluster,
        }))
      );
    });

    getRegions().then((retrievedRegions) => {
      setOptionsRegions(
        retrievedRegions
          .sort((regionA, regionB) => regionA.nomeRegione.localeCompare(regionB.nomeRegione))
          .map((region) => ({
            value: region.nomeRegione,
            label: region.nomeRegione,
          }))
      );
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (project.locations.nomeRegione) {
      getProvinceByRegion(project.locations.nomeRegione).then((retrievedUtss) =>
        setOptionsUtss(
          retrievedUtss
            .sort((utsA, utsB) =>
              utsA.nomeUnitaTerritoriale.localeCompare(utsB.nomeUnitaTerritoriale)
            )
            .map((uts) => ({
              value: uts.nomeUnitaTerritoriale,
              label: uts.nomeUnitaTerritoriale,
            }))
        )
      );
    }

    if (project.locations.nomeUnitaTerritoriale) {
      getTownByProvince(project.locations.nomeUnitaTerritoriale).then((retrievedTowns) =>
        setOptionsTowns(
          retrievedTowns
            .sort((townA, townB) => townA.nomeComune.localeCompare(townB.nomeComune))
            .map((town) => ({
              value: town,
              label: town.nomeComune,
            }))
        )
      );
    }
  }, [project.locations]);

  useEffect(() => {
    if (Object.keys(currentProject).length > 0) {
      setSelectedProponent(
        optionsProponents
          .filter((optionProponent) => optionProponent.value === project.proponentCode)
          .map((filteredProponent) => ({
            value: filteredProponent.value,
            label: filteredProponent.label,
          }))
      );

      setSelectedIntervention(
        optionsInterventions
          .filter(
            (optionIntervention) => optionIntervention.value === project.interventionClassCode
          )
          .map((filteredIntervention) => ({
            value: filteredIntervention.value,
            label: filteredIntervention.label,
          }))
      );

      let selectedFinancing;

      if (project.status === 'SUBMITTED') {
        // If the project status is "SENT", set selectedFinancing to the first matching financing type
        selectedFinancing = optionsFinancings.find(
          (optionFinancing) => optionFinancing.value === project.financingTypeCode
        );
      } else if (project.status === 'DRAFT') {
        // If the project status is "DRAFT", set selectedFinancing to the first matching financing type with status === 1
        selectedFinancing = optionsFinancings.find(
          (optionFinancing) =>
            optionFinancing.value === project.financingTypeCode && optionFinancing.active === 1
        );
      }
      // else {
      //   // For other project statuses, set selectedFinancing to the first matching financing type with status === 1
      //   selectedFinancing = optionsFinancings.find(optionFinancing => optionFinancing.active === 1);
      // }

      // Set the selected financing
      setSelectedFinancing(selectedFinancing);

      // setSelectedFinancing(
      //   optionsFinancings.filter(
      //     (optionFinancing) =>
      //       optionFinancing.value === project.financingTypeCode && project.status !== 'DRAFT'
      //   )
      // );

      setSelectedSize(
        optionsSizes
          .filter((optionSize) => optionSize.value === project.sizeCode)
          .map((filteredSize) => ({
            value: filteredSize.value,
            label: filteredSize.label,
            min: filteredSize.min,
            max: filteredSize.max,
          }))
      );

      setSelectedSector(
        optionsSectors
          .filter((optionSector) => optionSector.value === project.sectorCode)
          .map((filteredSector) => ({
            value: filteredSector.value,
            label: filteredSector.label,
          }))
      );

      setSelectedCluster(
        optionsClusters.filter(
          (optionCluster) => optionCluster.value === project.structureClusterCode
        )
      );

      setSelectedRegion(
        optionsRegions
          .filter((optionRegion) => optionRegion.value === project.locations.nomeRegione)
          .map((filteredRegion) => ({
            value: filteredRegion.value,
            label: filteredRegion.label,
          }))
      );

      setSelectedUts(
        optionsUtss
          .filter((optionUts) => optionUts.value === project.locations.nomeUnitaTerritoriale)
          .map((filteredUts) => ({
            value: filteredUts.value,
            label: filteredUts.label,
          }))
      );

      setSelectedTown(
        optionsTowns
          .filter((optionTown) => optionTown.label === project.locations.nomeComune)
          .map((filteredTown) => ({
            value: filteredTown.value,
            label: filteredTown.label,
          }))
      );
    }
  }, [
    currentProject,
    project,
    optionsProponents,
    optionsInterventions,
    optionsFinancings,
    optionsSizes,
    optionsSectors,
    optionsClusters,
    optionsRegions,
    optionsUtss,
    optionsTowns,
  ]);

  useEffect(() => {
    if (isConfirmed) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  useEffect(() => {
    if (isConfirmedInvestment) {
      submit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmedInvestment]);

  const onChange = (e, section, fieldName, setSelect) => {
    //console.log('e test:', e);
    //console.log('section test:', section);
    //console.log('fieldName test:', fieldName);

    switch (section) {
      case 'interventType':
        //if (fieldName === 'sectorCode' && e.value==="AUDIOVISIVO") { e.value="AUD"; console.log("HERE",e); onChange(e, 'interventType', 'proponentCode', setSelectedProponent) }
        if (fieldName === 'interventionClassCode') {
          if (e.value === 'NOINTPURCH') {
            setProject(() => ({
              ...project,
              [fieldName]: e.value,
              sroiEvaluationType: e.type,
              residualLife: 0,
              operationStartsAt: new Date().getFullYear(),
              investmentStartsAt: new Date().getFullYear(),

              expectedLifeSpan: 10,
            }));
          } else {
            setProject(() => ({
              ...project,
              [fieldName]: e.value,
              sroiEvaluationType: e.type,
              totalInvestment: null,
              residualLife: 0,
            }));
          }
        } else if (fieldName === 'proponentCode') {
          if (e.value === 'ET') {
            setProject(() => ({
              ...project,
              [fieldName]: e.value,
              expectedLifeSpan: 40,
              residualLife: 0,
              sroiEvaluationType: null,
              interventionClassCode: null,
              operationStartsAt: project.investmentStartsAt + 2,
            }));

            setSelectedIntervention([]);
          } else if (e.value === 'LIGHTPRO' || e.value === 'LIGHTNOPRO') {
            setProject(() => ({
              ...project,
              [fieldName]: e.value,
              interventionClassCode: null,
              // totalInvestment: null,
              residualLife: 0,
              sroiEvaluationType: null,
              sizeCode: 'XS',
            }));
            setSelectedIntervention([]);
            setSelectedSize(optionsSizes.filter((option) => option.value === 'XS'));
          } else if (e.value === 'AUD') {
            setProject(() => ({
              ...project,
              [fieldName]: e.value,
              expectedLifeSpan: 5,
              residualLife: 0,
              sroiEvaluationType: 1,
              operationStartsAt: project.investmentStartsAt + 1,
              interventionClassCode: 'NEW',
              sectorCode: 'AUDIOVISIVO',
              sizeCode: 'XS',
            }));

            setSelectedIntervention(
              optionsInterventions
                .filter((optionIntervention) => optionIntervention.value === 'NEW')
                .map((filteredIntervention) => ({
                  value: filteredIntervention.value,
                  label: filteredIntervention.label,
                }))
            );
            setSelectedSector(
              optionsSectors
                .filter((optionSector) => optionSector.value === 'AUDIOVISIVO')
                .map((filteredSector) => ({
                  value: filteredSector.value,
                  label: filteredSector.label,
                }))
            );
            setSelectedSize(
              optionsSizes
                .filter((optionSize) => optionSize.value === 'XS')
                .map((filteredSize) => ({
                  value: filteredSize.value,
                  label: filteredSize.label,
                  min: filteredSize.min,
                  max: filteredSize.max,
                }))
            );
          } else {
            setProject(() => ({
              ...project,
              [fieldName]: e.value,
              sectorCode: e.value === 'AUD' ? 'AUDIOVISIVO' : '',
              financingTypeCode: null,
              totalInvestment: null,
              structureClusterCode: null,
              residualLife: 0,
              // ...(e.value === 'LIGHTPRO' && { sizeCode: 'XS' }),
            }));

            setSelectedFinancing([]);
            setSelectedCluster([]);

            if (e.value === 'AUD') {
              setSelectedSector(optionsSectors.filter((option) => option.value === 'AUDIOVISIVO'));
            } else {
              setSelectedSector([]);
            }

            // if (e.value === 'LIGHTPRO') {
            //   setSelectedSize(optionsSizes.filter((option) => option.value === 'XS'));
            // }
          }
        } else if (fieldName === 'sectorCode' && e.value === 'AUDIOVISIVO') {
          setProject(() => ({
            ...project,
            [fieldName]: e.value,
            expectedLifeSpan: 5,
            residualLife: 0,
            sroiEvaluationType: 1,
            operationStartsAt: project.investmentStartsAt + 1,
            interventionClassCode: 'NEW',
            proponentCode: 'AUD',
            sizeCode: 'XS',
          }));

          setSelectedProponent(
            optionsProponents
              .filter((optionProponent) => optionProponent.value === 'AUD')
              .map((filteredProponent) => ({
                value: filteredProponent.value,
                label: filteredProponent.label,
              }))
          );
          setSelectedIntervention(
            optionsInterventions
              .filter((optionIntervention) => optionIntervention.value === 'NEW')
              .map((filteredIntervention) => ({
                value: filteredIntervention.value,
                label: filteredIntervention.label,
              }))
          );

          setSelectedSize(
            optionsSizes
              .filter((optionSize) => optionSize.value === 'XS')
              .map((filteredSize) => ({
                value: filteredSize.value,
                label: filteredSize.label,
                min: filteredSize.min,
                max: filteredSize.max,
              }))
          );
        } else {
          setProject(() => ({
            ...project,
            [fieldName]: e.target ? Number(e.target.value) : e.value,
          }));
        }

        if (setSelect) {
          setSelect([e]);
        }
        break;
      case 'locations':
        setSelect([e]);

        if (fieldName === 'nomeRegione') {
          setProject(() => ({
            ...project,
            [section]: {
              ...project[section],
              [fieldName]: e.value,
              nomeUnitaTerritoriale: '',
              nomeComune: '',
            },
          }));

          setSelectedUts([]);
          setSelectedTown([]);
          setOptionsTowns([]);
        } else if (fieldName === 'nomeUnitaTerritoriale') {
          setProject(() => ({
            ...project,
            [section]: {
              ...project[section],
              [fieldName]: e.value,
              nomeComune: '',
            },
          }));

          setSelectedTown([]);
        } else if (fieldName === 'comune') {
          setProject(() => ({
            ...project,
            [section]: e.value,
            locationCode: e.value.codComuneNumerico,
            address: {
              ...project.address,
              locationCode: e.value.codComuneNumerico,
            },
          }));
        }

        break;
      case 'address':
        setProject(() => ({
          ...project,
          [section]: {
            ...project[section],
            [fieldName]: e.target.value,
          },
        }));

        break;
      default:
        if (project.proponentCode === 'ET' && fieldName === 'investmentStartsAt') {
          setProject(() => ({
            ...project,
            [fieldName]: Number(e.target.value),
            operationStartsAt: Number(e.target.value) + 2,
          }));
        } else if (project.proponentCode === 'AUD' && fieldName === 'investmentStartsAt') {
          setProject(() => ({
            ...project,
            [fieldName]: Number(e.target.value),
            operationStartsAt: Number(e.target.value) + 1,
          }));
        } else {
          if (section === 'inputNumber') {
            setProject(() => ({
              ...project,
              [e.originalEvent.target.name]: e.value,
            }));
          } else {
            setProject(() => ({
              ...project,
              [e.target.name]:
                e.target.type !== 'number'
                  ? e.target.value
                  : e.target.value
                  ? Number(e.target.value)
                  : '',
            }));
          }
        }
    }
  };

  const onBlur = () => {
    if (
      (Object.keys(currentProject).length === 0 && project.requestId) ||
      (Object.keys(currentProject).length > 0 && currentProject.requestId !== project.requestId)
    ) {
      getRequestIdCheck(project.requestId).then((retrievedCheck) => {
        retrievedCheck ? setRequestIDInvalid(true) : setRequestIDInvalid(false);
      });
    } else {
      setRequestIDInvalid(false);
    }
  };

  const onBlurInvestment = (totalInvestment) => {
    let realSize = optionsSizes
      .filter((size) => size.min <= Number(totalInvestment) && size.max > Number(totalInvestment))
      .pop();

    if (!realSize) {
      realSize = optionsSizes.filter((size) => size.max <= Number(totalInvestment)).pop();
    }
    if (realSize) {
      setProject(() => ({
        ...project,
        sizeCode: realSize.value,
      }));

      setSelectedSize(
        optionsSizes
          .filter((optionSize) => optionSize.value === realSize.value)
          .map((filteredSize) => ({
            value: filteredSize.value,
            label: filteredSize.label,
            min: filteredSize.min,
            max: filteredSize.max,
          }))
      );
    }
  };

  const validateForm = () => {
    let restProject;

    if (project.proponentCode === 'ET') {
      const {
        address,
        esgassessments,
        esgratings,
        interventionclasses,
        locations,
        owner,
        proponents,
        questions,
        revenueGenerating,
        sectors,
        sizes,
        submittedAt,
        subsectors,
        interventionClassCode,
        sroiEvaluationType,
        femaleaud,
        ...restProj
      } = project;

      restProject = restProj;
    } else if (project.proponentCode === 'LIGHTPRO' || project.proponentCode === 'LIGHTNOPRO') {
      const {
        address,
        esgassessments,
        esgratings,
        interventionclasses,
        locations,
        owner,
        proponents,
        questions,
        revenueGenerating,
        sectors,
        sizes,
        submittedAt,
        subsectors,
        interventionClassCode,
        sroiEvaluationType,
        femaleaud,
        ...restProj
      } = project;
      // const {
      //   address,
      //   esgassessments,
      //   esgratings,
      //   interventionclasses,
      //   locations,
      //   owner,
      //   proponents,
      //   questions,
      //   revenueGenerating,
      //   sectors,
      //   sizes,
      //   submittedAt,
      //   subsectors,
      //   interventionClassCode,
      //   // financingTypeCode,
      //   //totalInvestment,
      //   // structureClusterCode,
      //   femaleaud,
      //   ...restProj
      // } = project;

      restProject = restProj;
    } else if (project.interventionClassCode === 'NOINTPURCH') {
      const {
        address,
        esgassessments,
        esgratings,
        interventionclasses,
        locations,
        owner,
        proponents,
        questions,
        revenueGenerating,
        sectors,
        sizes,
        submittedAt,
        subsectors,
        financingTypeCode,
        structureClusterCode,
        femaleaud,
        investmentStartsAt,
        residualLife,
        operationStartsAt,
        expectedLifeSpan,
        ...restProj
      } = project;

      restProject = restProj;
    } else if (project.proponentCode === 'AUD') {
      const {
        address,
        esgassessments,
        esgratings,
        interventionclasses,
        locations,
        owner,
        proponents,
        questions,
        revenueGenerating,
        sectors,
        sizes,
        submittedAt,
        subsectors,
        financingTypeCode,
        totalInvestment,
        structureClusterCode,
        femaleaud,
        ...restProj
      } = project;

      restProject = restProj;
    } else {
      const {
        address,
        esgassessments,
        esgratings,
        interventionclasses,
        locations,
        owner,
        proponents,
        questions,
        revenueGenerating,
        sectors,
        sizes,
        submittedAt,
        subsectors,
        financingTypeCode,
        totalInvestment,
        // structureClusterCode,
        femaleaud,
        ...restProj
      } = project;

      restProject = restProj;
    }

    const selections =
      project.proponentCode === 'ET' ||
      project.proponentCode === 'LIGHTPRO' ||
      project.proponentCode === 'LIGHTNOPRO'
        ? [
            selectedProponent,
            selectedFinancing,
            selectedSize,
            selectedSector,
            selectedCluster,
            selectedRegion,
            selectedUts,
            selectedTown,
          ]
        : [
            selectedProponent,
            selectedIntervention,
            selectedSize,
            selectedSector,
            selectedRegion,
            selectedUts,
            selectedTown,
          ];

    console.log('restProject', restProject);

    const isProjectFilled = Object.values(restProject).every((prop) => prop !== '' && prop != null)
      ? true
      : false;
    //console.log('isProjectFilled', isProjectFilled);

    const areSelectionsFilled = selections.every((selection) => Object.values(selection).length > 0)
      ? true
      : false;
    //console.log('areSelectionsFilled', areSelectionsFilled);

    const isAddressFilled = Object.values(project.address).every(
      (prop) => prop !== '' && prop != null
    )
      ? true
      : false;
    //console.log('isAddressFilled', isAddressFilled);

    const isEverythingVerified = isProjectFilled && areSelectionsFilled && isAddressFilled;
    //console.log('isEverythingVerified', isEverythingVerified);

    return isEverythingVerified;
  };

  const saveNewProject = async () => {
    const createResult = await createProject(project);

    return createResult;
  };

  const editCurrentProject = async () => {
    console.log('project in edit prima:', project);
    const editResult = await editProject(project);

    return editResult;
  };

  const checkConfirmation = async (boolean) => {
    setIsConfirmed(boolean);

    boolean ? submit() : setShowModalEdit(false);
  };

  const checkConfirmationInvestment = async (boolean) => {
    setIsConfirmedInvestment(boolean);

    boolean ? submit() : setShowModalEditInvestment(false);
  };

  const submit = async (e) => {
    e?.preventDefault();

    const isEverythingVerified = validateForm();

    if (!isEverythingVerified) {
      DangerToast('Attenzione', 'Tutti i campi sono obbligatori.');
      return;
    }

    if (project.expectedLifeSpan > 50) {
      DangerToast('Attenzione', 'La vita utile deve essere minore o uguale a 50.');

      return;
    }

    if (project.residualLife > project.expectedLifeSpan) {
      DangerToast('Attenzione', 'La vita residua non può essere maggiore della vita utile.');

      return;
    }

    if (
      project.proponentCode === 'ET' ||
      project.proponentCode === 'LIGHTPRO' ||
      project.proponentCode === 'LIGHTNOPRO' ||
      project.interventionClassCode === 'NOINTPURCH'
    ) {
      const sizeCopy = [...selectedSize];
      //console.log('sizeCopy:', sizeCopy);
      const size = sizeCopy.pop();
      //console.log('size:', size);

      const isIncludedInSize =
        size.min === 0
          ? project.totalInvestment >= size.min && project.totalInvestment <= size.max
          : project.totalInvestment > size.min && project.totalInvestment <= size.max;

      if (!isIncludedInSize && !isConfirmedInvestment) {
        setShowModalEditInvestment(true);
        return;
      }
    }

    //console.log('project submit:', project);

    let typeOfProject;
    let result;

    if (Object.keys(currentProject).length > 0) {
      if (
        (currentProject.proponentCode !== project.proponentCode ||
          currentProject.sizeCode !== project.sizeCode ||
          currentProject.sectorCode !== project.sectorCode) &&
        !isConfirmed
      ) {
        setShowModalEdit(true);

        return;
      } else {
        result = await editCurrentProject();
        typeOfProject = 'edit';
      }
    } else {
      result = await saveNewProject();
      typeOfProject = 'new';
    }

    //console.log('result:', result);

    if (result.id) {
      SuccessToast(
        'Operazione conclusa',
        `Progetto ${typeOfProject === 'edit' ? 'modificato' : 'creato'} correttamente`
      );

      setTimeout(() => {
        window.location.assign(`/valutazione/${result.id}/esg`);
      }, 2000);
    } else {
      DangerToast(
        'Attenzione',
        `Errore nella ${typeOfProject === 'edit' ? 'modifica' : 'creazione'} del progetto`
      );
    }
  };

  const renderTooltip = (props) => {
    let message = '';

    if (props.popper.state) {
      message = props.popper.state.options.testObj;
    }

    return (
      <Tooltip id='button-tooltip' {...props}>
        {message}
      </Tooltip>
    );
  };

  const inputProponent = (
    <>
      <Form.Group as={Col} controlId='proponentCode'>
        <Form.Label>
          <OETrans code='ICS005' />
          {/*  {translations.filter(trans => trans.code === 'ICS005').map(trans => trans.text)}
            <OverlayTrigger
               placement="top"
               delay={{ show: 200, hide: 400 }}
               overlay={renderTooltip}
               popperConfig={{testObj: translations.filter(trans => trans.code === 'ICS005').map(trans => trans.description)}}
            >
               <TooltipInfoIcon className="tooltip-ico" style={{width: '1rem'}} />
            </OverlayTrigger> */}
        </Form.Label>
        <Select
          name='proponentCode'
          placeholder='Seleziona...'
          value={selectedProponent}
          options={optionsProponents}
          onChange={(e) => onChange(e, 'interventType', 'proponentCode', setSelectedProponent)}
          isDisabled={project.status === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputFinancingType = (
    <>
      <Form.Group as={Col} controlId='financingTypeCode'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS049').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS049')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          name='financingTypeCode'
          placeholder='Seleziona...'
          value={selectedFinancing}
          options={optionsFinancings.filter((el) => el.active !== 0)}
          onChange={(e) => onChange(e, 'interventType', 'financingTypeCode', setSelectedFinancing)}
          isDisabled={project.status === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  /* const inputTotalInvestment = (
    <>
      <Form.Group as={Col} controlId='totalInvestment'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS050').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS050')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Form.Control
          type='number'
          name='totalInvestment'
          size='lg'
          value={project.totalInvestment ?? ''}
          onChange={(e) => onChange(e, 'interventType', 'totalInvestment')}
          disabled={project.status === 'SUBMITTED' ? true : false}
          {...(project.proponentCode === 'ET' && { onBlur: (e) => onBlurInvestment(e) })}
        />
      </Form.Group>
    </>
  ); */

  const inputCluster = (
    <>
      <Form.Group as={Col} controlId='structureClusterCode'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS051').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS051')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          name='structureClusterCode'
          placeholder='Seleziona...'
          value={selectedCluster}
          options={optionsClusters}
          onChange={(e) => onChange(e, 'interventType', 'structureClusterCode', setSelectedCluster)}
          isDisabled={project.status === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputInterventType = (
    <>
      <Form.Group as={Col} controlId='intervention'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS006').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS006')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          name='interventionClassCode'
          placeholder='Seleziona...'
          value={selectedIntervention}
          options={optionsInterventions}
          onChange={(e) =>
            onChange(e, 'interventType', 'interventionClassCode', setSelectedIntervention)
          }
          isDisabled={project.status === 'SUBMITTED' ? true : false}
        />
      </Form.Group>
    </>
  );

  const inputSize = (
    <>
      <Form.Group as={Col} controlId='sizeCode'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS007').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS007')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          name='sizeCode'
          placeholder='Seleziona...'
          value={selectedSize}
          options={optionsSizes}
          onChange={(e) => onChange(e, 'interventType', 'sizeCode', setSelectedSize)}
          isDisabled={
            project.status === 'SUBMITTED' ||
            project.proponentCode === 'LIGHTPRO' ||
            project.proponentCode === 'LIGHTNOPRO'
              ? true
              : false
          }
        />
      </Form.Group>
    </>
  );

  const inputSector = (
    <>
      <Form.Group as={Col} controlId='sectorCode'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS008').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS008')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <Select
          name='sectorCode'
          placeholder='Seleziona...'
          value={selectedSector}
          options={optionsSectors}
          onChange={(e) => onChange(e, 'interventType', 'sectorCode', setSelectedSector)}
          isDisabled={
            project.status === 'SUBMITTED' || project.proponentCode === 'AUD' ? true : false
          }
        />
      </Form.Group>
    </>
  );

  const inputNewNumber = (
    <>
      <Form.Group as={Col} controlId='totalInvestment'>
        <Form.Label>
          {translations.filter((trans) => trans.code === 'ICS050').map((trans) => trans.text)}
          <OverlayTrigger
            placement='top'
            delay={{ show: 200, hide: 400 }}
            overlay={renderTooltip}
            popperConfig={{
              testObj: translations
                .filter((trans) => trans.code === 'ICS050')
                .map((trans) => trans.description),
            }}>
            <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
          </OverlayTrigger>
        </Form.Label>
        <div>
          <InputNumber
            name='totalInvestment'
            value={project.totalInvestment}
            onChange={(e) => onChange(e, 'inputNumber')}
            onBlur={(e) => onBlurInvestment(e.target.ariaValueNow)}
            mode='currency'
            minFractionDigits={0}
            locale={`${localStorage.getItem('userLanguage').toLowerCase()}-${localStorage.getItem(
              'userLanguage'
            )}`}
            currency='EUR'
            disabled={project.status === 'SUBMITTED' ? true : false}
            className='lg'
          />
        </div>
      </Form.Group>
    </>
  );

  return (
    <>
      <Form onSubmit={(e) => submit(e)} id='dati-generali-form'>
        <div>
          <Row>
            <Col>
              <div className='simple-box' style={{ height: '90%' }}>
                <h5 className='title-simple-box margin-title-box'>
                  {translations
                    .filter((trans) => trans.code === 'ICS031')
                    .map((trans) => trans.text)}
                </h5>

                <Form.Group as={Col} controlId='clientId'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS002')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS002')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='clientId'
                    size='lg'
                    defaultValue={project.clientId}
                    onChange={(e) => onChange(e)}
                    disabled={
                      project.status === 'SUBMITTED' ||
                      (localStorage.ics_role === '1' && project.clientId)
                        ? true
                        : false
                    }
                  />
                </Form.Group>

                <Form.Group as={Col} controlId='requestId'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS003')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS003')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='requestId'
                    size='lg'
                    defaultValue={project.requestId}
                    onChange={(e) => onChange(e)}
                    onBlur={() => onBlur()}
                    isInvalid={requestIDInvalid}
                    disabled={
                      project.status === 'SUBMITTED' ||
                      (localStorage.ics_role === '1' && project.requestId)
                        ? true
                        : false
                    }
                  />
                  <Form.Control.Feedback
                    type='invalid'
                    tooltip={false}
                    className='position-absolute'>
                    {translations
                      .filter((trans) => trans.code === 'ICS115')
                      .map((trans) => trans.text)}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div className='simple-box' style={{ height: '90%' }}>
                <h5 className='title-simple-box margin-title-box'>
                  {translations
                    .filter((trans) => trans.code === 'ICS032')
                    .map((trans) => trans.text)}
                </h5>
                <Form.Group as={Col} controlId='description'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS004')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS004')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    style={{ minHeight: '9rem' }}
                    as='textarea'
                    name='description'
                    defaultValue={project.description}
                    onChange={(e) => onChange(e)}
                    disabled={project.status === 'SUBMITTED' ? true : false}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>

        <div className='simple-box' style={{ marginTop: '40px' }}>
          <Row>
            <Col lg={12} className='form-login'>
              <h5 className='title-simple-box margin-title-box'>
                {translations.filter((trans) => trans.code === 'ICS033').map((trans) => trans.text)}
              </h5>

              {(project.proponentCode === 'ET' ||
                project.proponentCode === 'LIGHTPRO' ||
                project.proponentCode === 'LIGHTNOPRO') && (
                <>
                  <Row className='mb-3'>
                    {inputProponent}
                    {inputFinancingType}
                    {inputSector}
                  </Row>
                  <Row>
                    {inputSize}
                    {/* {inputTotalInvestment} */}
                    {inputNewNumber}
                    {inputCluster}
                  </Row>
                </>
              )}
              {project.proponentCode === 'AUD' && (
                <Row className='mb-3'>
                  {inputProponent}

                  {inputSector}
                </Row>
              )}
              {/* {project.proponentCode !== 'AUD' &&
                project.proponentCode !== 'ET' &&
                project.proponentCode !== 'LIGHTPRO' && (
                  <Row className='mb-3'>
                    {inputProponent}
                    {inputInterventType}
                    {inputSize}
                    {project.interventionClassCode === 'NOINTPURCH' && inputNewNumber}
                    {inputSector}
                  </Row>
                )} */}
              {project.proponentCode !== 'AUD' &&
              project.proponentCode !== 'ET' &&
              project.proponentCode !== 'LIGHTPRO' &&
              project.proponentCode !== 'LIGHTNOPRO' &&
              project.interventionClassCode !== 'NOINTPURCH' ? (
                <>
                  <Row className='mb-3'>
                    {inputProponent}
                    {inputInterventType}
                    {inputSize}
                  </Row>
                  <Row>
                    {inputSector}
                    {inputCluster}
                  </Row>
                </>
              ) : project.interventionClassCode === 'NOINTPURCH' ? (
                <>
                  <Row className='mb-3'>
                    {inputProponent}
                    {inputInterventType}
                    {inputSector}
                  </Row>
                  <Row className='mb-3'>
                    {inputSize}
                    {inputNewNumber}
                  </Row>
                </>
              ) : null}

              {/* {(project.proponentCode === 'LIGHTPRO' || project.proponentCode === 'LIGHTNOPRO') && (
                <>
                  <Row className='mb-3'>
                    {inputProponent}
                  
                    {inputFinancingType}
                    {inputSector}
                  </Row>
                  <Row>
                    {inputSize}
                    {project.proponentCode === 'LIGHTPRO' && inputNewNumber}
                    {inputNewNumber}
                    {inputCluster}
                  </Row>
                </>
              )} */}
            </Col>
          </Row>
        </div>

        <div className='simple-box' style={{ marginTop: '40px' }}>
          <Row>
            <Col lg={12} className='form-login'>
              <h5 className='title-simple-box margin-title-box'>
                {translations.filter((trans) => trans.code === 'ICS034').map((trans) => trans.text)}
              </h5>
              <Row className='mb-3'>
                <Form.Group as={Col} controlId='nomeRegione'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS009')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS009')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    name='nomeRegione'
                    placeholder='Seleziona...'
                    value={selectedRegion}
                    options={optionsRegions}
                    isSearchable={true}
                    onChange={(e) => onChange(e, 'locations', 'nomeRegione', setSelectedRegion)}
                    isDisabled={project.status === 'SUBMITTED' ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='nomeUnitaTerritoriale'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS010')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS010')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    name='nomeUnitaTerritoriale'
                    placeholder='Seleziona...'
                    value={selectedUts}
                    options={optionsUtss}
                    isSearchable={true}
                    onChange={(e) =>
                      onChange(e, 'locations', 'nomeUnitaTerritoriale', setSelectedUts)
                    }
                    isDisabled={project.status === 'SUBMITTED' ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='codComune'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS011')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS011')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Select
                    name='codComune'
                    placeholder='Seleziona...'
                    value={selectedTown}
                    options={optionsTowns}
                    isSearchable={true}
                    onChange={(e) => onChange(e, 'locations', 'comune', setSelectedTown)}
                    isDisabled={project.status === 'SUBMITTED' ? true : false}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} controlId='address'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS012')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS012')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='address'
                    size='lg'
                    value={project.address.address}
                    onChange={(e) => onChange(e, 'address', 'address')}
                    disabled={project.status === 'SUBMITTED' ? true : false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId='cap'>
                  <Form.Label>
                    {translations
                      .filter((trans) => trans.code === 'ICS013')
                      .map((trans) => trans.text)}
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 200, hide: 400 }}
                      overlay={renderTooltip}
                      popperConfig={{
                        testObj: translations
                          .filter((trans) => trans.code === 'ICS013')
                          .map((trans) => trans.description),
                      }}>
                      <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    name='cap'
                    size='lg'
                    value={project.address.cap}
                    onChange={(e) => onChange(e, 'address', 'cap')}
                    disabled={project.status === 'SUBMITTED' ? true : false}
                  />
                </Form.Group>
                <Col></Col>
              </Row>
            </Col>
          </Row>
        </div>

        {project.interventionClassCode !== 'NOINTPURCH' && (
          <div className='simple-box' style={{ marginTop: '40px' }}>
            <Row>
              <Col lg={12} className='form-login'>
                <h5 className='title-simple-box margin-title-box'>
                  {translations
                    .filter((trans) => trans.code === 'ICS035')
                    .map((trans) => trans.text)}
                </h5>
                <Row className='mb-3'>
                  <Form.Group as={Col} controlId='investmentStartsAt'>
                    <Form.Label>
                      {translations
                        .filter((trans) => trans.code === 'ICS014')
                        .map((trans) => trans.text)}
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{
                          testObj: translations
                            .filter((trans) => trans.code === 'ICS014')
                            .map((trans) => trans.description),
                        }}>
                        <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type='number'
                      size='lg'
                      min={1900}
                      max={3000}
                      name='investmentStartsAt'
                      defaultValue={project.investmentStartsAt}
                      onChange={(e) => onChange(e, undefined, 'investmentStartsAt')}
                      disabled={project.status === 'SUBMITTED' ? true : false}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='operationStartsAt'>
                    <Form.Label>
                      {translations
                        .filter((trans) => trans.code === 'ICS015')
                        .map((trans) => trans.text)}
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{
                          testObj: translations
                            .filter((trans) => trans.code === 'ICS015')
                            .map((trans) => trans.description),
                        }}>
                        <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                      </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type='number'
                      size='lg'
                      min={project.investmentStartsAt}
                      max={3000}
                      name='operationStartsAt'
                      value={project.operationStartsAt}
                      onChange={(e) => onChange(e)}
                      disabled={
                        project.status === 'SUBMITTED' ||
                        project.proponentCode === 'ET' ||
                        project.proponentCode === 'AUD'
                          ? true
                          : false
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId='expectedLifeSpan'>
                    <Form.Label>
                      {translations
                        .filter((trans) => trans.code === 'ICS016')
                        .map((trans) => trans.text)}
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{
                          testObj: translations
                            .filter((trans) => trans.code === 'ICS016')
                            .map((trans) => trans.description),
                        }}>
                        <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                      </OverlayTrigger>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type='number'
                        min={1}
                        max={50}
                        size='lg'
                        name='expectedLifeSpan'
                        value={project.expectedLifeSpan ?? ''}
                        onChange={(e) => onChange(e)}
                        disabled={
                          project.status === 'SUBMITTED' || project.proponentCode === 'AUD'
                            ? true
                            : false
                        }
                      />
                      <InputGroup.Text className='addon-for-number'>anni</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} controlId='residualLife'>
                    <Form.Label>
                      {translations
                        .filter((trans) => trans.code === 'ICS037')
                        .map((trans) => trans.text)}
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 400 }}
                        overlay={renderTooltip}
                        popperConfig={{
                          testObj: translations
                            .filter((trans) => trans.code === 'ICS037')
                            .map((trans) => trans.description),
                        }}>
                        <TooltipInfoIcon className='tooltip-ico' style={{ width: '1rem' }} />
                      </OverlayTrigger>
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type='number'
                        size='lg'
                        min={0}
                        max={project.expectedLifeSpan}
                        name='residualLife'
                        //defaultValue={project.residualLife}
                        value={project.residualLife}
                        onChange={(e) => onChange(e)}
                        disabled={
                          project.status === 'SUBMITTED' ||
                          // project.interventionClassCode !== 'AMM' ||
                          project.proponentCode === 'AUD' ||
                          project.sroiEvaluationType === '1'
                            ? true
                            : false
                        }
                      />
                      <InputGroup.Text className='addon-for-number'>anni</InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        <TutorialsButtonWrapper
          url={'https://www.youtube.com/embed/DnYvK0SpFzk?si=xnSUh618FNUJNeGb'}
          videoTitle={'Tutorial introduzione e regole generali'}
        />

        {project.status && project.status === 'SUBMITTED' ? null : (
          <Button
            type='submit'
            form='dati-generali-form'
            variant='primary'
            className='assessment-button'>
            SALVA
          </Button>
        )}
      </Form>

      <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Modificando il tipo di intervento, la posizione o la durata, azzererai le operazioni
          svolte nel questionario ESG o SROI.
          <br />
          <br />
          Sei sicuro di voler continuare?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={() => checkConfirmation(false)}>
            Annulla
          </Button>
          <Button variant='primary' onClick={() => checkConfirmation(true)}>
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModalEditInvestment}
        onHide={() => setShowModalEditInvestment(false)}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>Attenzione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Il totale dell'investimento non è compreso nella taglia dell'investimento selezionato
          <br />
          <br />
          Sei sicuro di voler continuare?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={() => checkConfirmationInvestment(false)}>
            Annulla
          </Button>
          <Button variant='primary' onClick={() => checkConfirmationInvestment(true)}>
            Conferma
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ValutazioneGeneral;
